@import '../../styles/var';

.mpk-appbar{
  background: $mpk-appbar-color;
  height: 64px;
  padding: 0 16px;
  &.inverse{
    // background: $mpk-primary-1;
    background:  linear-gradient(-64deg, $mpk-primary-0, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1);
    > *, .rmd-button {
      color: white
    }
  }
}