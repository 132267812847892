@import '../../styles/var';

.mpk-picture{
  background: #d6d0c9;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  position: relative;
  border: 2px solid white;
  .rmd-icon{
    font-size: 16px;
    color: $mpk-primary-color;
  }
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }
  .default-img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
}
  