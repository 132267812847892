#mpn-container{
  text-align: center;
  width: 100%;
  max-width: 400px;
  height: 560px;
  margin: 0 auto;
}

@media screen and (max-height: 560px){
  #mpn{
    height: 100vh
  }
}