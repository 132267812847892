@import '../../styles/var.scss';

.mpk-custom-text-field{
  .message{
    font-size: $mpk-font-size-NS;
    padding: $mpk-padding-S 0;
    &.error-text{
      color: $mpk-color-warn;
    }
    &.help-text{
      color: $mpk-font-color-D3;
    }
  }
}