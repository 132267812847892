.mpk-notification{
    .badges{
        position: absolute;
        top: 4px;
        right: 4px;
        width: auto;
        padding: 2px 4px;
        border-radius: 4px;
        font-size: 10px;
        background-color: red;
        color: white;
    }
    .notification-item{
        
    }
}