.pr-news-ticker{
  position: relative;
  bottom: 0;
  background: transparent;
  z-index: 10;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .news-ticker-item{
    color: inherit;
    display: flex; 
    align-items: center;
    height: 24px;
  }
  &.sp{
    background:#1c2f0c;
    .news-ticker-item-divider{
        background: #689f38;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        text-align: center;
        margin-left: 24px;
    }
  }
}
      