@import '../../styles/var';

.mpk-menu{
  width: 100%;
  .subheader{
    cursor: pointer;
    position: sticky;
    top: 0;
    background: $mpk-background-color;
    z-index: 2;
  }
  .menus{
    li{
      list-style: none;
    }
    .rmd-icon{
      font-size: 10px;
      color: $mpk-font-color-D4;
    }
    .menu-item{
      border-top: thin solid rgba(0,0,0,.026);
      font-weight: 400;
      &.active{
        color: $mpk-primary-color;
        font-weight: 600;
        .rmd-icon{
          color: $mpk-primary-color;
        }
      }
    }
  }
  .menu-group{
    border-bottom: thin solid $mpk-border-color-dark;
  }
  .menu-group-divider{
    color: $mpk-font-color-D3;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.mpk-menu{
  width: 100%;
  .subheader{
    cursor: pointer;
    position: sticky;
    top: 0;
    background: $mpk-background-color;
    z-index: 2;
  }
  .menus-z{
    padding-left: 40px;
    padding-bottom: 10px;
    li{
      list-style: none;
      height: 30px;
    }
    .rmd-icon{
      font-size: 16px !important;
      color: $mpk-font-color-D4;
    }
    .p {
      font-size: 14 !important;
    }
    .menu-item{
      padding: 5px;
      border-top: thin solid rgba(0,0,0,.026);
      font-weight: 400;
      font-size: 16px !important;
      cursor: pointer;
      &.active{
        color: $mpk-primary-color;
        font-weight: 400;
        .rmd-icon{
          color: $mpk-primary-color;
        }
      }
    }
    .menu-item:hover{
      background: #f5f5f5 !important;
    }
  }
  .menu-group{
    border-bottom: thin solid $mpk-border-color-dark;
  }
  .menu-group-divider{
    color: $mpk-font-color-D3;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}