@import '../../styles/var.scss';

.mpk-sidebar{
  width: 260px;
  background: $mpk-sidebar-color;
  .mpk-sidebar-footer{
    height: 32px;
    .btn-support-center{
      padding: 0 16px;
      span{
        line-height: 2.2;
      }
    }
  }
}