@import '../../styles/var';

.mpk-data-view-summary{
  min-width: 240px;
  margin-top: 8px;
  .trend{
    &.up{
      color: $mpk-color-success;
      .rmd-icon{
        color: inherit;
      }
    }
    &.down{
      color: $mpk-color-warn;
      .rmd-icon{
        color: inherit
      }
    }
  }
}