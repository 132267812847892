@import "../styles/var";

.mpk-tabs-wrapper{
    &.show-scroll-control{
        .rmd-tabs{
            padding-left: 48px;
            padding-right: 48px;
            border-bottom: thin solid $mpk-border-color-dark;
        }
    }
    &.vertical{
        .rmd-tab-panels{
            overflow: auto;
            height: 100% !important;
            // padding: $mpk-padding-N;
        }
        .rmd-tabs{
            &:after{
                display: none;
            }
            width: 260px;
            height: 100%;
            overflow: auto;
            flex-direction: column;
            border-right: thin solid $mpk-border-color-dark;
            padding-left: 0;
            padding-right: 0;
            .rmd-tab{
                justify-content: space-between;
                height: auto;
                padding: 12px 16px;
                flex: none;
                position: relative;
                span{
                    white-space: normal;
                    text-align: left;
                    flex: 1;
                    padding-left: 8px;
                    line-height: 20px;
                }
                &.rmd-tab--active{
                    &:after{
                        content: '';
                        background-color: #9c27b0;
                        right: 0;
                        left: unset;
                        top: 0;
                        width: 4px;
                        height: 100%;
                        position: absolute;
                        display: block;
                    }
                }
            }
        }
    }
    .scroll-ctrl{
        position: absolute;
        top: 0;
        border-radius: 0;
        background-color: #f3f0ed;
        z-index: 10;
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
    }
}