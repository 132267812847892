@import '../../styles/var';

.mpk-hint{
  background: $mpk-hint-background-color;
  color: $mpk-hint-color;
  border: thin solid $mpk-hint-color;
  .rmd-icon{
    color: $mpk-hint-color;
  }
  &.no-border{
    border: none
  }
}