
@import '../../styles/var.scss';

.mpk-divider-label{
    width: 100%;
    display: flex;
    align-items: center;
    &:after{
        content: '';
        flex: 1;
        border-top: 2px solid $mpk-primary-1;
        margin-left: 8px;
    }
}