.mpk-company-card{
  max-width: 320;
  margin: 0 auto;
  position: relative;
  // height: 100%;
  border-radius: 4px;
  .image{
    width: 100%;
    height: 240px;
    .rmd-icon{
      font-size: 120px !important;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.thumbnail{
    .image{
      width: 64px;
      height: 64px;
      .rmd-icon{
        font-size: 48px !important;
      }
    }
  }
}