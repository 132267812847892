.wrapper {
  border: 3px solid blue;
}

.container {
  background-color: white;
}

.barCompleted {
  background-color: #c3362b;
}

.label {
  font-size: 20px;
  color: green;
}
